import { KEYS, KEYS_SOURCE } from "../../../utils/keys";

export const keyMap = [
    { code: 602, value: KEYS.home },
    { code: 404, value: KEYS.lang },
    { code: 405, value: KEYS.messages },
    { code: 461, value: KEYS.back },
    { code: 427, value: KEYS.channelUp },
    { code: 428, value: KEYS.channelDown },

    { code: 112, value: KEYS.home, source: KEYS_SOURCE.RF },
    { code: 120, value: KEYS.back, source: KEYS_SOURCE.RF },
    { code: 33, value: KEYS.channelUp, source: KEYS_SOURCE.RF },
    { code: 34, value: KEYS.channelDown, source: KEYS_SOURCE.RF },
    { code: 118, value: KEYS.messages, source: KEYS_SOURCE.RF },
    { code: 113, value: KEYS.lang, source: KEYS_SOURCE.RF },

];
