import { SESSION } from "./session";
import {
    isActiveSequence,
    orderSignageByActiveLevelAndId,
    ScheduleDailyType,
    ScheduleDaysType,
    TimeRanges,
} from "./signageUtils";
import { figmaWidthPx2Px } from "./utils";

export const TICKER_TAPE = {
    SIZE: {
        SMALL: { fontSize: 32, bgHeight: 80 },
        MEDIUM: { fontSize: 36, bgHeight: 91 },
        LARGE: { fontSize: 40, bgHeight: 96 },
    },
    SPEED: { SLOW: 900, MEDIUM: 600, FAST: 400 },
};

export enum TickerTape_Banner_Size {
    SMALL = "SMALL",
    MEDIUM = "MEDIUM",
    LARGE = "LARGE",
}
export enum TickerTape_Speed {
    SLOW = "SLOW",
    MEDIUM = "MEDIUM",
    FAST = "FAST",
}
export type Colors = {
    bgColor: string;
    fgColor: string;
};

export type TickerTape = {
    name: string;
    id: number;
    displayScrollSpeed: TickerTape_Speed;
    displayTextSize: TickerTape_Banner_Size;
    content: {
        [key: string]: string;
    };
    displayStyles: Colors;
    available: boolean;
    scheduleFrequencyRepetitions: number;
    scheduleFrequencyLimited: boolean;
    scheduleFrequencyDisplay: string;
    scheduleObtrusiveMode: boolean;
    scheduleEnd: string;
    scheduleStart: string;
    scheduleDaysType: ScheduleDaysType | null;
    scheduleDaysOfWeek: number[];
    scheduleDailyType: ScheduleDailyType | null;
    timeRanges: TimeRanges[];
};
export enum X_Axis {
    LEFT = "LEFT",
    CENTER = "CENTER",
    RIGHT = "RIGHT",
}
export enum Y_Axis {
    TOP = "TOP",
    MIDDLE = "MIDDLE",
    BOTTOM = "BOTTOM",
}

type Banner_Position = {
    x: X_Axis;
    y: Y_Axis;
};

export const BANNER = {
    SIZE: {
        SMALL: { width: 533, height: 300 },
        MEDIUM: { width: 911, height: 481 },
        LARGE: { width: 1288, height: 680 },
        BUTTONS: { height: 104 },
    },
};

export type Banner = {
    name: string;
    id: number;
    position: Banner_Position;
    displayPosition: DisplayPosition;
    displaySize: TickerTape_Banner_Size;
    contentImage: { libraryRef?: string; externalUrl?: string };
    contentAction: { type: string; value?: string };
    content: {
        asset: string;
        navigation: string;
    };
    scheduleObtrusiveMode: boolean;
    scheduleFrequencyRepetitions: number;
    scheduleFrequencyLimited: boolean;
    scheduleFrequencyDisplay: string;
    scheduleDuration: string;
    scheduleEnd: string;
    scheduleStart: string;
    scheduleDaysType: ScheduleDaysType | null;
    scheduleDaysOfWeek: number[];
    scheduleDailyType: ScheduleDailyType | null;
    timeRanges: TimeRanges[];
};

export const getBannerMargins = (
    banner: Banner,
    bannerImageSize?: { width: number; height: number },
): { x_align: string; y_align: string } => {
    if (!banner) {
        return { x_align: "auto", y_align: "auto" };
    }
    const x_center = (document.body.offsetWidth - 2 * figmaWidthPx2Px(72)) / 2;
    let _x = "";
    let _y = "";
    switch (banner.position.x) {
        case X_Axis.CENTER:
            _x =
                x_center -
                (bannerImageSize?.width || figmaWidthPx2Px(BANNER.SIZE[banner.displaySize].width)) / 2 +
                "px";
            break;
        case X_Axis.RIGHT:
            _x = "auto";
            break;
        default:
            break;
    }
    switch (banner.position.y) {
        case Y_Axis.MIDDLE:
            _y = "my-auto";
            break;
        case Y_Axis.BOTTOM:
            _y = "mt-auto";
            break;
        default:
            break;
    }

    return { x_align: _x, y_align: _y };
};

export const checkPriorityOfItems = (array: TickerTape[] | Banner[]) => {
    return orderSignageByActiveLevelAndId(
        array?.map((item) => {
            const itemActiveness = isActiveSequence(item);
            return {
                ...item,
                active: itemActiveness.active,
                activeLevel: itemActiveness.level,
                intervalTime: itemActiveness.intervalTime,
            };
        }),
    );
};

export const getTextOfTickerTape = (tickerTape: TickerTape) => {
    if (tickerTape?.content && Object.keys(tickerTape.content).length === 1) {
        const uniqueLang = Object.keys(tickerTape.content)[0] || SESSION.projectDefaultLang;
        return tickerTape?.content?.[uniqueLang];
    }
    if (SESSION.isCommonZone() && tickerTape?.content && Object.keys(tickerTape.content).length > 1) {
        // add all translates 1- projectDefaultLang 2-'en' if is no projectDefaultLang 3-rest
        let allTranslates = tickerTape?.content?.[SESSION.projectDefaultLang];
        if (SESSION.projectDefaultLang !== "en" && Object.keys(tickerTape.content).includes("en")) {
            allTranslates = `${allTranslates}   ${tickerTape.content["en"]}`;
        }

        Object.keys(tickerTape.content).forEach((contentLang) => {
            if (contentLang !== SESSION.projectDefaultLang && contentLang !== "en")
                allTranslates = `${allTranslates}   ${tickerTape.content[contentLang]}`;
        });
        return allTranslates;
    }
    const lang = localStorage.getItem("lang") || SESSION.projectDefaultLang;
    return tickerTape?.content?.[lang] || tickerTape?.content?.[SESSION.projectDefaultLang];
};

enum DisplayPosition {
    BOTTOM_CENTER = "BOTTOM_CENTER ",
    TOP_CENTER = "TOP_CENTER",
    TOP_RIGHT = "TOP_RIGHT",
    MIDDLE_RIGHT = "MIDDLE_RIGHT",
    BOTTOM_LEFT = "BOTTOM_LEFT",
    TOP_LEFT = "TOP_LEFT",
    MIDDLE_LEFT = "MIDDLE_LEFT",
    MIDDLE_CENTER = "MIDDLE_CENTER",
    BOTTOM_RIGHT = "BOTTOM_RIGHT",
}

export const getBannerAxisPosition = (displayPosition: DisplayPosition): Banner_Position => {
    switch (displayPosition) {
        case DisplayPosition.BOTTOM_LEFT:
            return { x: X_Axis.LEFT, y: Y_Axis.BOTTOM };
        case DisplayPosition.BOTTOM_CENTER:
            return { x: X_Axis.CENTER, y: Y_Axis.BOTTOM };
        case DisplayPosition.BOTTOM_RIGHT:
            return { x: X_Axis.RIGHT, y: Y_Axis.BOTTOM };
        case DisplayPosition.TOP_LEFT:
            return { x: X_Axis.LEFT, y: Y_Axis.TOP };
        case DisplayPosition.TOP_CENTER:
            return { x: X_Axis.CENTER, y: Y_Axis.TOP };
        case DisplayPosition.TOP_RIGHT:
            return { x: X_Axis.RIGHT, y: Y_Axis.TOP };
        case DisplayPosition.MIDDLE_LEFT:
            return { x: X_Axis.LEFT, y: Y_Axis.MIDDLE };
        case DisplayPosition.MIDDLE_CENTER:
            return { x: X_Axis.CENTER, y: Y_Axis.MIDDLE };
        case DisplayPosition.MIDDLE_RIGHT:
            return { x: X_Axis.RIGHT, y: Y_Axis.MIDDLE };
        default:
            return { x: X_Axis.CENTER, y: Y_Axis.MIDDLE };
    }
};
